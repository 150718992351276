<template>
  <div>
    <el-table ref="multiTable" :data="parentForm.mdnc_part_list" border @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="48" align="center" />
      <el-table-column label="序号" width="60" align="center">
        <template v-slot="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="模具部件" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具部件</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_name'" :rules="[{ required: true }]">
            <el-input
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_name"
              show-word-limit
              @change="val => setAttrVal(val, scope.$index, 3, scope.row)"
              placeholder="请填写模具部件"
              :maxlength="20"
            >
            </el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="对应模具数量" width="200">
        <template slot="header">
          <span class="vg_deep_red">对应模具数量</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_dnum'" :rules="[{ required: true }]">
            <el-input
              @change="() => changeNum(scope.row)"
              @input="val => (parentForm.mdnc_part_list[scope.$index].mdnc_part_dnum = helper.keepTNum(val))"
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_dnum"
              show-word-limit
              placeholder="请填写对应数量"
              :maxlength="6"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="对应穴数" width="200">
        <template slot="header">
          <span class="vg_deep_red">对应穴数</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_hole'" :rules="[{ required: true }]">
            <el-input
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_hole"
              show-word-limit
              placeholder="请填写对应穴数"
              :maxlength="20"
            ></el-input>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模具类型" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具类型</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_type'" :rules="[{ required: true }]">
            <el-select
              filterable
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_type"
              @change="val => setAttrVal(val, scope.$index, 1, scope.row)"
              placeholder="请选择模具类型"
              size="small"
            >
              <el-option v-for="item in mouldTypeList" :key="item.id" :label="item.label" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模具种类" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具种类</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_kind'" :rules="[{ required: true }]">
            <el-select
              filterable
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_kind"
              @change="val => setAttrVal(val, scope.$index, 2, scope.row)"
              placeholder="请选择模具种类"
              size="small"
            >
              <el-option v-for="item in mdinKindList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模具材质编号" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具材质编号</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_mtqt'">
            <el-select
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_mtqt"
              placeholder="请选择模具材质编号"
              @change="val => setAttrVal(val, scope.$index, 0, scope.row)"
              size="small"
            >
              <el-option v-for="item in mouldTextList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模具属性" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具属性</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_attr'" :rules="[{ required: true }]">
            <el-tooltip
              class="item"
              effect="light"
              :content="parentForm.mdnc_part_list[scope.$index].mdnc_part_attr.join('/')"
              placement="top-start"
            >
              <el-input :value="parentForm.mdnc_part_list[scope.$index].mdnc_part_attr.join('/')" disabled placeholder="自动生成" />
            </el-tooltip>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模盘尺寸" width="200">
        <template slot="header">
          <span class="vg_deep_red">模盘尺寸</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_spec'" :rules="[{ required: true }]">
            <el-input v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_spec" show-word-limit placeholder="请填写模盘尺寸" />
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="表面处理" width="200">
        <template slot="header">
          <span class="vg_deep_red">表面处理</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_skin'" :rules="[{ required: true }]">
            <el-select v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_skin" placeholder="请选择表面处理" size="small" multiple>
              <el-option v-for="item in surfaceTreatmentList" :key="item.id" :label="item.label" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模具存储地" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具存储地</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdnc_part_supp'" :rules="[{ required: true }]">
            <el-select
              filterable
              v-model="parentForm.mdnc_part_list[scope.$index].mdnc_part_supp"
              placeholder="请选择模具存储地"
              size="small"
            >
              <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
      <el-table-column label="模具生产商" width="200">
        <template slot="header">
          <span class="vg_deep_red">模具生产商</span>
        </template>
        <template v-slot="scope">
          <el-form-item label-width="0" :prop="'mdnc_part_list.' + scope.$index + '.mdin_producer'" :rules="[{ required: true }]">
            <el-select
              filterable
              v-model="parentForm.mdnc_part_list[scope.$index].mdin_producer"
              placeholder="请选择模具生产商"
              size="small"
            >
              <el-option v-for="item in suppList" :key="item.supp_id" :label="item.supp_name" :value="item.supp_id"> </el-option>
            </el-select>
          </el-form-item>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import helper from '@assets/js/helper';

export default {
  computed: {
    helper() {
      return helper;
    }
  },
  props: {
    parentForm: {
      type: Object,
      required: true
    },
    suppList: {
      type: Array
    }
  },
  data() {
    return {
      multiSelection: [],
      custOptionList: [],
      mouldTypeList: [
        { id: 0, label: '生产模:P' },
        { id: 1, label: '样品模:S' }
      ],
      surfaceTreatmentList: [
        { id: '1', label: '光面' },
        { id: '2', label: '哑面' },
        { id: '3', label: '晒纹' }
      ],
      mdinKindList: [
        { id: 0, label: '橡胶成型模:01' },
        { id: 1, label: '橡胶硫化模:02' },
        { id: 2, label: '注塑模:03' },
        { id: 3, label: '热合模:04' },
        { id: 4, label: '辅助工具:05' }
      ],
      mouldTextList: [
        { id: 1, label: '橡胶:01' },
        { id: 2, label: 'PA6:02' },
        { id: 3, label: 'TPR:03' },
        { id: 4, label: 'TPE:04' },
        { id: 5, label: 'TPU:05' },
        { id: 6, label: 'PP:06' },
        { id: 7, label: 'ABS:07' },
        { id: 8, label: 'PET:08' },
        { id: 9, label: '吹塑EVA:09' },
        { id: 10, label: '射出EVA:10' }
      ]
    };
  },
  components: {
    // textEnlargement
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {},
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = [];
      this.multiSelection = val;
      this.$emit('handleSelectionChange', this.multiSelection);
    },
    /**
     * 检测变更改变模具属性值
     * @param {*} val 变更值
     * @param {number} tableIndex 当前行索引
     * @param {number} index 当前变更字段索引
     */
    setAttrVal(val, tableIndex, index, row) {
      if (this.parentForm.mdnc_part_list[tableIndex].mdnc_part_attr.length === 0) {
        let temp = new Array(5);
        // let notZeroIndex = tableIndex + 1;
        // temp[4] = notZeroIndex < 10 ? '0' + notZeroIndex : notZeroIndex;
        this.parentForm.mdnc_part_list[tableIndex].mdnc_part_attr.push(temp.join('-'));
      }
      let label = '';
      let subLabel = '';
      switch (index) {
        case 0:
          label = this.mouldTextList.find(x => x.id === val).label;
          subLabel = label.substring(label.indexOf(':') + 1);
          break;
        case 1:
          label = this.mouldTypeList.find(x => x.id === val).label;
          subLabel = label.substring(label.indexOf(':') + 1);
          break;
        case 2:
          label = this.mdinKindList.find(x => x.id === val).label;
          subLabel = label.substring(label.indexOf(':') + 1);
          break;
        case 3:
          let length = val.split('/').length;
          subLabel = val ? (length < 10 ? '0' + length : length) : '';
          break;
      }
      let mdncPartAttr = row.mdnc_part_attr[0].split('-');
      mdncPartAttr[index] = subLabel;
      row.mdnc_part_attr[0] = mdncPartAttr.join('-');
      this.changeNum(row);
    },
    changeNum(row) {
      let tempArr = [];
      if (row.mdnc_part_dnum > 0) {
        for (let i = 0; i < Number(row.mdnc_part_dnum); i++) {
          let temp = row.mdnc_part_attr[0].split('-');
          temp[4] = i + 1;
          tempArr.push(temp.join('-'));
          row.mdnc_part_attr = tempArr;
        }
      }
    },
    removeSearchBox() {
      // this.$refs.elAutocompleteRef.activated = false;
    }
  }
};
</script>

<style scoped lang="scss">
.vd_total {
  font-size: 24px;
  text-align: right;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
}

.vd_color {
  color: #c70e0e;
}
</style>
